var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-list",
    {
      attrs: {
        size: "large",
        rowKey: "id",
        loading: _vm.loading,
        itemLayout: "vertical",
        dataSource: _vm.data,
      },
      scopedSlots: _vm._u([
        {
          key: "renderItem",
          fn: function (item) {
            return _c(
              "a-list-item",
              { key: item.id },
              [
                _c("template", { slot: "actions" }),
                _c(
                  "a-list-item-meta",
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          slot: "title",
                          href: "https://vue.ant.design/",
                        },
                        slot: "title",
                      },
                      [_vm._v(_vm._s(item.title))]
                    ),
                    _c("template", { slot: "description" }, [
                      _c(
                        "span",
                        [
                          _c("a-tag", [_vm._v("Ant Design")]),
                          _c("a-tag", [_vm._v("设计语言")]),
                          _c("a-tag", [_vm._v("蚂蚁金服")]),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
                _c("article-list-content", {
                  attrs: {
                    description: item.description,
                    owner: item.owner,
                    avatar: item.avatar,
                    href: item.href,
                    updateAt: item.updatedAt,
                  },
                }),
              ],
              2
            )
          },
        },
      ]),
    },
    [
      _vm.data.length > 0
        ? _c(
            "div",
            {
              staticStyle: { "text-align": "center", "margin-top": "16px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "a-button",
                {
                  attrs: { loading: _vm.loadingMore },
                  on: { click: _vm.loadMore },
                },
                [_vm._v("加载更多")]
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }